import {eventEmitter} from "../common/baseData";

/**
 * Класс для работы с модальными окнами
 * @var type String - указывает на тип открываемого окна
 * @var center Bool - указывает на то будет ли окно центрировано
 *
 * Каждое окно имеет свой уникальный id по которому можно определить соответсвие в событиях
 */
class Popup {
	constructor(type, center, middle, isSecondLevel, isWithoutBackModal) {
		this._type = type;
		this._wrapper = document.createElement('div');
		this._wrapper.className = `modal fade in ${(center) ? 'center-modal' : ''} ${(isWithoutBackModal) ? 'without-backdrop' : ''}`;
		this._wrapper.id = 'modal-main';
		this._wrapper.setAttribute('role', 'dialog');
		this._wrapper.tabIndex = '-1';
		this._inner = document.createElement('div');
		this._inner.className = `modal-dialog modal-dialog--${type}`;
		if(middle) this._inner.className += ' middle';
		// Make wrapper element
		this._content = document.createElement('div');
		this._content.className = `modal-content modal-content--${type} modal--${type}`;
		// Make close element
		this._close = document.createElement('a');
		this._close.href = '#';
		this._close.className = `close-modal`;
		this._close.dataset['dismiss'] = 'modal';
		// Make body holder
		this._bodyHolder = document.createElement('div');
		this._bodyHolder.className = `modal-body modal-body--${type}`;

		this._content.appendChild(this._close);
		this._content.appendChild(this._bodyHolder);
		this._inner.appendChild(this._content);
		this._wrapper.appendChild(this._inner);
		// Backgdrop element
		this._backdrop = document.createElement('div');
		!isWithoutBackModal ? this._backdrop.className = 'modal-backdrop fade in' : '';
		// Make unique id
		this.id = Math.floor(Math.random() * (1000000000 - 1)) + 1;
		this._isSecondLevel = isSecondLevel;
		this._isWithoutBackModal = isWithoutBackModal;
		this.state = 'closed';
	}

	set id(uniqueId) {
		this._id = uniqueId;
	}

	get id() {
		return this._id;
	}

	set opened(state) {
		this._state = state;
	}

	get opened() {
		return this._state;
	}

	set body(html) {
		this._bodyHolder.innerHTML = html;
		eventEmitter.emit('popupBodyInserted', this);
	}

	get body() {
		return this._bodyHolder;
	}

	open() {
		let self = this;
		eventEmitter.emit('popupBeforeOpen', this);
		// Append
		document.body.appendChild(this._wrapper);
		document.body.appendChild(this._backdrop);
		eventEmitter.emit('popupAfterOpen', this);
		!this._isWithoutBackModal ? document.body.classList.add('modal-open') : '';
		if (this._isSecondLevel) {
			document.body.classList.add('modal-open-second');
		}
		// Открытие любого окна, если не соответсвует нашему - закрываем наше
		eventEmitter.once('popupBeforeOpen', (popup) => {
			if (self.id !== popup.id && self.opened)
				self.close();
		});
		// Закрытие окна по клику на кнопку закрыть пользователем
		this._close.addEventListener('click', (e) => {
			e.preventDefault();
			self.close();
			if (this._isWithoutBackModal) {
				// Получаем текущую дату и время
				const now = new Date();
				const formattedDate = now.toISOString().slice(0, 10).replace(/-/g, ''); // Преобразуем в формат YYYYMMDD
				const expirationDate = new Date(now.getTime() + 60 * 60 * 24 * 1000 * 7).toUTCString(); // Дата истечения через 7 дней

				// Записываем в куки
				document.cookie = `commercial-banner-date=${formattedDate}; expires=${expirationDate}; path=/;`;
			}
		});

        this._wrapper.addEventListener('click', (e) => {
            if (self._inner.contains(e.target)) return;
            self.close();
		});

        document.addEventListener('keyup', (e) => {
            e.preventDefault();
            if(e.key === 'Escape') self.close();
		});

        document.addEventListener('keyup', (e) => {
            e.preventDefault();
            if(e.key === 'ArrowLeft') $('.slick-prev.slick-arrow').click();
        });

        document.addEventListener('keyup', (e) => {
            e.preventDefault();
            if(e.key === 'ArrowRight') $('.slick-next.slick-arrow').click();
        });

		this.opened = true;
	}

	close() {
		try {
			eventEmitter.emit('popupBeforeClose', this);
			document.body.removeChild(this._wrapper);
			document.body.removeChild(this._backdrop);
			eventEmitter.emit('popupAfterClose', this);
			if (this._isSecondLevel) {
				document.body.classList.remove('modal-open-second');
			} else {
				document.body.classList.remove('modal-open');
			}
			this.opened = false;
		} catch (error) {
			console.error(error);
		}

	}
}

export {Popup}