'use strict';

import {Popup} from "../popup";
import './style.css';

class CommercialBanner extends Popup {
	constructor(data) {
		super('commercialBanner', false, false, false, true);
	}
}

export {CommercialBanner}