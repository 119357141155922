'use strict';

class PreLoader {
    constructor(params) {
        this.eventEmitter = params.eventEmitter;
    }

    initLoader() {
        this.eventEmitter.on('showLoader', () => {
            let loaderNode = document.querySelector('.loader-container');
            if (!loaderNode) {
                let node = document.createElement('div');
                node.className = 'loader-container';
                document.body.appendChild(node);
            }
        });

        this.eventEmitter.on('hideLoader', () => {
            let loaderNode = document.querySelector('.loader-container');
            if (loaderNode instanceof HTMLElement)
                loaderNode.parentNode.removeChild(loaderNode);
        });
    }
}


export default PreLoader;