'use strict';

import './style.css';

class HeaderBanner {
    constructor(node, params) {
        this.node = node;
        this.params = params;
        this.desktopPictureNode = null;
        this.mobilePictureNode = null;
        this.closeButton = null;
        this.showMobilePicture = false;
    }

    render() {

        this.desktopPictureNode = this.node.querySelector('.d-banner');
        this.mobilePictureNode = this.node.querySelector('.m-banner');
        this.closeButton = this.node.querySelector('.header-banner_close');

        /*let link = document.createElement('a');
        link.className = 'header-banner';
        link.setAttribute('href', this.params.link);

        let picture = (webpSupport && this.params.hasOwnProperty('optimizePictureList') && this.params.optimizePictureList.picture)
            ? this.params.optimizePictureList.picture : this.params.picture;
        let pictureMobile = (webpSupport && this.params.hasOwnProperty('optimizePictureList') && this.params.optimizePictureList.pictureMobile)
            ? this.params.optimizePictureList.pictureMobile : this.params.pictureMobile;

        if (picture.length > 0) {
            this.desktopPictureNode = document.createElement('span');
            this.desktopPictureNode.className = 'd-banner';
            this.desktopPictureNode.setAttribute('data-src', picture);
            link.append(this.desktopPictureNode);
        }

        if (pictureMobile.length > 0) {
            this.mobilePictureNode = document.createElement('span');
            this.mobilePictureNode.className = 'm-banner';
            this.mobilePictureNode.setAttribute('data-src', pictureMobile);
            link.append(this.mobilePictureNode);
            this.showMobilePicture = true;
        }

        this.node.append(link);

        if (this.showMobilePicture) {
            this.closeButton = document.createElement('button');
            this.closeButton.className = 'header-banner_close';
            this.node.append(this.closeButton);
        }*/

        return this;
    }

    webpSupport() {
        return new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = function () {
                resolve({'status': true});
            };
            img.onerror = function () {
                resolve({'status': false});
            };
            img.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
        });
    }

    adaptParentsNode() {
        let headerNode = document.querySelector('header');
        if (headerNode instanceof HTMLElement) {
            if (this.mobilePictureNode instanceof HTMLElement)
                headerNode.classList.add('show_mob_banner');
            if (this.desktopPictureNode instanceof HTMLElement)
                headerNode.classList.add('show_banner');
        }

        if (this.mobilePictureNode instanceof HTMLElement) {
            let mainBlockNode = document.querySelector('main');
            if (mainBlockNode instanceof HTMLElement)
                mainBlockNode.classList.add('mobile_banner');
        }


        return this;
    }

    checkScreenWidth() {
        let documentWidth = Number(document.documentElement.clientWidth);
        if (documentWidth <= 1023) {
            if (this.mobilePictureNode)
                this.mobilePictureNode.style.backgroundImage = `url('${this.mobilePictureNode.dataset.src}')`;
        } else {
            if (this.desktopPictureNode)
                this.desktopPictureNode.style.backgroundImage = `url('${this.desktopPictureNode.dataset.src}')`;

        }
    }

    bindEvents() {
        let self = this;

        /*self.checkScreenWidth();
        window.addEventListener('resize', function (e) {
            self.checkScreenWidth();
        });*/

        if (this.closeButton instanceof HTMLElement) {
            this.closeButton.addEventListener('click', () => {
                this.mobilePictureNode.remove();
                this.closeButton.remove();
                this.node.classList.remove('mobile');

                let headerNode = document.querySelector('header');
                if (headerNode instanceof HTMLElement)
                    headerNode.classList.remove('show_mob_banner');

                let logoNode = document.querySelector('.l-show-mobile-banner');
                if(logoNode instanceof HTMLElement)
                    logoNode.classList.remove('l-show-mobile-banner');

                let mainBlockNode = document.querySelector('main');
                if (mainBlockNode instanceof HTMLElement)
                    mainBlockNode.classList.remove('mobile_banner');

                self.sendRequest();
            });
        }

        return this;
    }

    sendRequest() {
        let formData = new FormData();
        formData.append('action', 'closeHeaderBanner');
        fetch(this.params.ajaxPath, {method: 'POST', body: formData});
    }
}

export {HeaderBanner};