import {PhoneValidation} from '../../phoneValidation';
var Inputmask = require('inputmask');

class AuthRegister {
    constructor(data) {
        this._outer = false;
        this._phoneType = 'phoneAuthorization';
        this._emailType = 'emailAuthorization';
        this._emailPattern = /^([a-zA-Z0-9_.+-])+(\@([a-zA-Z0-9-])+\.)+.+([a-zA-Z0-9])+$/;
        this._namePattern = /^[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ]+([ʼ'-][a-zA-ZА-Яа-яЁёЇїІіЄєҐґ]+)?(\s[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ']+)?$/;
        this._lastNamePattern = /^[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ]+([ʼ'-][a-zA-ZА-Яа-яЁёЇїІіЄєҐґ]+)?(\s[a-zA-ZА-Яа-яЁёЇїІіЄєҐґ']+)?$/;
        this._steps = {
            'auth': ['searchUser', 'authUser', 'authorizedUser'],
            'register': ['fillRegisterData', 'registeredUser'],
            'forgot': ['fillForgotData', 'successPasswordReset']
        };
        this._userInfo = [];
        this._modes = ['auth', 'register', 'forgot'];
        this._user = false;
        this._mode = null;
        this._step = null;
        this._loader = null;
        this._dataLayer = window.dataLayer = window.dataLayer || [];
        this._authType = '';
        this._authParam = false;
        this._authValue = false;
        this._contentType = 'application/json; charset=utf-8';
        this.setLangMessages(data.messages);
        this.url = data.url;
        this.menuPath = data.menuPath;
        this.sessid = data.sessid;
        this.lang = data.lang;
        this.eventEmitter = data.eventEmitter;
        this._hasResendSms = false;
        this._sendingRequest = false;
        this._phoneConfirmForm = false;
    }

    initTemplate() {
        this.setOuter(this.getModalTemplate());
        this.setBackDrop(this.getModalBackDrop());
        return this;
    }


    initLoader() {
        this._loader = document.createElement('div');
        this._loader.className = 'loader-container';

        this.eventEmitter.on('showLoader', () => {
            document.body.appendChild(this._loader.cloneNode(true));
        });

        this.eventEmitter.on('hideLoader', () => {
            let loaderNode = document.querySelector('.loader-container');
            if (loaderNode instanceof HTMLElement)
                loaderNode.parentNode.removeChild(loaderNode);
        });
    }

    getDataLayer() {
        return this._dataLayer;
    }

    switchPasswdFieldType(event) {
        let el = event.target, passwordField, passwordEye;
        let passwdHolder = el.closest('.password_holder');
        if (passwdHolder) {
            passwordField = passwdHolder.querySelector('.password_field');
            passwordEye = passwdHolder.querySelector('.pass_icon');
            (passwordEye.classList.contains('active_eye')) ? passwordEye.classList.remove('active_eye') : passwordEye.classList.add('active_eye');
            passwordField.setAttribute('type', (passwordField.getAttribute('type') === 'password') ? 'text' : 'password');
        }
    }


    addDataLayerEvent() {
        var dataLayer = this.getDataLayer(), event = false, param = false;

        switch (this.getCurrentStep()) {
            case 'searchUser':
                event = 'Authorization';
                param = 'Начало авторизации, ввод параметра авторизации.';
                break;
            case 'authUser':
                event = 'Authorization';
                if (this.getAuthType() === 'phoneAuthorization') {
                    param = 'Ввод проверочного кода.';
                } else if (this.getAuthType() === 'emailAuthorization') {
                    param = 'Ввод пароля.';
                }
                break;
            case 'authorizedUser':
                event = 'Authorization';
                param = 'Успешная авторизация.';
                break;
            case 'fillRegisterData':
                event = 'Registration';
                param = 'Заполнение данных регистрации.';
                break;
            case 'registeredUser':
                event = 'Registration';
                param = 'Успешная регистрация.';
                break;
        }

        dataLayer.push({
            'event': event,
            'event_param': param
        });
        return this;
    }

    setOuter(el) {
        this._outer = el;
        return this;
    }

    getOuter() {
        return this._outer;
    }

    setBackDrop(el) {
        this._backDrop = el;
        return this;
    }

    getBackDrop() {
        return this._backDrop;
    }

    get url() {
        return this._url;
    }

    set url(url) {
        this._url = url;
    }

    get personalMenuLoaded() {
        return this._personalMenuLoaded;
    }

    set personalMenuLoaded(url) {
        this._personalMenuLoaded = url;
    }

    get menuPath() {
        return this._menuPath;
    }

    set menuPath(url) {
        this._menuPath = url;
    }

    get hasResendSms() {
        return this._hasResendSms;
    }

    set hasResendSms(status) {
        this._hasResendSms = status;
    }

    get sendingRequest() {
        return this._sendingRequest;
    }

    set sendingRequest(status) {
        this._sendingRequest = status;
    }

    addUserInfo(message) {
        this._userInfo.push(message);
        return this;
    }

    getUserInfo() {
        return this._userInfo;
    }

    resetUserInfo() {
        this._userInfo = [];
        return this;
    }

    switchMode(mode) {
        if (mode === 'auth') {
            this.setCurrentMode('auth').setCurrentStep('searchUser').initStep();
        } else if (mode === 'register') {
            this.setCurrentMode('register').setCurrentStep('fillRegisterData').initStep();
        } else if (mode === 'forgot') {
            this.setCurrentMode('forgot').setCurrentStep('fillForgotData').initStep();
        }
    }

    show() {
        const body = document.querySelector('body');
        this.clearSpecialMessage();
        this.eventEmitter.emit('popupBeforeOpen', this);
        body.classList.add('modal-open');
        body.appendChild(this.getOuter());
        body.appendChild(this.getBackDrop());
        this.getBackDrop().classList.add('in');
        setTimeout(() => {
            this.getOuter().classList.add('in');
        }, 250);

        let input = this.getOuter().querySelector('form [name="user_input_email_phone"]');

        if (this.getAuthType() === 'emailAuthorization') {
            input = this.getOuter().querySelector('form [name="user_input_password"]');
        } else if (this.getAuthType() === 'phoneAuthorization') {
            input = this.getOuter().querySelector('form [name="user_input_1"]');
        }

        if (input instanceof HTMLElement)
            input.focus();

        return this;
    }

    closeAuthRegist() {
        const body = document.querySelector('body');
        body.querySelector('.burger-menu').classList.remove('opened');
        body.querySelector('#header').classList.remove('opened-menu');
        body.classList.remove('side-open');
        body.querySelector('#menu-layout').remove();
    }

    showRegiter() {
        const body = document.querySelector('body');
        this.clearSpecialMessage();
        this.eventEmitter.emit('popupBeforeOpen', this);
        // body.appendChild(this.getOuter());
        // body.appendChild(this.getBackDrop());
        body.classList.add('modal-open');
        this.getBackDrop().classList.add('in');
        this.getOuter().classList.add('in');
        return this;
    }

    hide() {
        var body = document.querySelector('body');
        body.classList.remove('modal-open');
        this.getBackDrop().classList.remove('in');
        this.getOuter().classList.remove('in');
        setTimeout(() => {
            body.removeChild(this.getOuter());
            body.removeChild(this.getBackDrop());
        }, 250);
        return this;
    }

    showTextContent() {
        let textHolder = document.getElementById('text-holder')
        if(textHolder instanceof HTMLElement) return textHolder.innerHTML = `
            <span>${this.getMessage('AUTH_SMS_CODE_NOT_DELIVERED')}</span>
            <a href="#" class="resend_code">${this.getMessage('AUTH_SMS_CODE_RESEND')}</a>
        `
    }

    showTimer(sec) {
        let textHolder = document.getElementById('text-holder')
        if(textHolder instanceof HTMLElement) return textHolder.innerHTML = `${this.getMessage('AUTH_TIMER_TITLE')} ${sec} c.`
    }

    goToNextPiece(el) {
        if (el.nextElementSibling instanceof HTMLElement) el.nextElementSibling.focus();
    }

    goToPrevPiece(el) {
        if (el.previousElementSibling instanceof HTMLElement) {
            el.previousElementSibling.value = '';
            el.previousElementSibling.focus();
        }
    }

    checkInput(input) {
        let valid = this.validateInput(input);
        // Check register step values
        if (valid && this.getCurrentStep() === 'fillRegisterData' &&
            (input.getAttribute('name') === 'user_input_phone')) {
            this.searchUserOnRegistration(input.value);
        }
        this.determineValueAvailability(input);
    }

    determineValueAvailability(input) {
        if (input.value.length > 0) input.classList.add('has-value');
        else input.classList.remove('has-value');
        return this;
    }

    validateInput(input) {
        var valid;

        input.addEventListener('change', (e) => {
            switch (input.getAttribute('name')) {
                case 'user_input_email':
                    valid = this._emailPattern.test(input.value);
                    break;
                case 'user_input_name':
                    valid = this._namePattern.test(input.value);
                    break;
                case 'user_input_lastName':
                    valid = this._lastNamePattern.test(input.value);
                    break;
                case 'user_input_phone':
                    valid = this.validatePhone(input.value);
                    break;
                case 'user_input_email_phone':
                    valid = this.validatePhone(input.value);
                    break;
                case 'user_input_checkword':
                    valid = (parseInt(input.value.length, 10) === 32);
                    break;
                default:
                    break;
            }

            if (valid) this.hideError(input);
            else this.showInputError(input);
        });

        return valid;
    }

    async validatePhone(phoneNumber) {
        let valid = true;
        if (phoneNumber.length <= 0) valid = false;
        else if (phoneNumber.length === 1 && phoneNumber.indexOf('+') === 0) valid = false;
        else if (phoneNumber.length > 1) {
            try {
                if (phoneNumber.indexOf('+') !== 0) {
                    phoneNumber = '+' + phoneNumber;
                }

                // Асинхронный вызов PHP скрипта с использованием axios
                const response = await axios.post('/local/api/validatephonenumber/validate', {
                    phoneNumber: phoneNumber,
                    sessid: sessid,
                    lang: lang
                }, axiosConfig);

                let data = response.data;
                valid = data.valid;
            } catch (e) {
                valid = false;
            }
        }
        return valid;
    }

    checkFullPinCode(node) {
        let success = false;
        let inputList = node.querySelector('form [name=user_input_1]');
        if (inputList instanceof HTMLElement) {
            success = (inputList.value.length === 4);
        }

        return success;
    }

    getForm() {
        return this.getOuter().querySelector('form');
    }

    initStep() {
        var self = this;
        this.getOuter().querySelector('form').innerHTML = this.getFormTemplate();

        if (this.getAuthType() === 'phoneAuthorization') {
            let input = this.getOuter().querySelector('form [name="user_input_1"]');
            if (input instanceof HTMLElement) {

                input.setAttribute('autofocus', 'autofocus');
                input.focus();
            }
        }

        this.updateModeBlock().clearRequestError();

        if (this.getCurrentStep() === 'searchUser') {
            this.createPhoneNumberMask();
        }

        // Authorized user 3 sec redirect
        if (this.getCurrentStep() === 'authorizedUser' || this.getCurrentStep() === 'registeredUser')
            setTimeout(function () {
                window.location.reload(true);
            }, 3000);
        else this.eventEmitter.emit('hideLoader');

        if (this.getCurrentStep() === 'successPasswordReset') {
            setTimeout(function () {
                self.setCurrentMode('auth').setCurrentStep('authUser').initStep();
            }, 3000);
        }

        this.addDataLayerEvent();

        return this;
    }

    createPhoneNumberMask() {
        let phoneInputNode = this.getOuter().querySelector('form [name="user_input_email_phone"]');
        if (!(phoneInputNode instanceof HTMLElement)) return;

        import('inputmask').then(module => {
            /** @param im Inputmask **/
            let im = new module.default();
            let codeInsert = 0;
            // Set current mask
            im.option({alias: '+999(99)999-99-99'});
            im.mask(phoneInputNode);
            phoneInputNode.value = '380';
        });
    }

    updateModeBlock() {
        let title = this.getOuter().querySelector('.modal-title'),
            switchLink = this.getOuter().querySelector('.registration-link');
        if (this.getCurrentMode() === 'auth') {
            title.textContent = this.getMessage('AUTH_LOGIN_BUTTON');
            switchLink.textContent = this.getMessage('AUTH_LOGIN_REGISTER');
            switchLink.dataset['mode'] = 'register';
        } else if (this.getCurrentMode() === 'register') {
            let phoneInputMask = document.querySelector("[name=user_input_phone]");
            let emailInputMask = document.getElementById("email-input-mask");

            if(phoneInputMask && emailInputMask) {
                var im = new Inputmask("+380(99)999-99-99");
                im.mask(phoneInputMask);
                // email input mask
                var emailMask = new Inputmask("*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}.*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]");
                emailMask.mask(emailInputMask);
            }

            title.textContent = this.getMessage('AUTH_LOGIN_REGISTER');
            switchLink.textContent = this.getMessage('AUTH_LOGIN_BUTTON');
            switchLink.dataset['mode'] = 'auth';
        } else if (this.getCurrentMode() === 'forgot') {
            title.textContent = this.getMessage('AUTH_RESTORE_TITLE');
            switchLink.textContent = this.getMessage('AUTH_LOGIN_BUTTON');
            switchLink.dataset['mode'] = 'auth';
        }
        return this;
    }

    collectUserStepInput() {
        var data = false, el = false, valid = true;

        switch (this.getCurrentStep()) {
            case 'searchUser':
                // Find input element
                el = this.getOuter().querySelector('form [name="user_input_email_phone"]');
                // Test value
                if (!this.validatePhone(el.value) && !this._emailPattern.test(el.value)) {
                    valid = false;
                    this.showInputError(el);
                } else data = el.value;

                break;
            case 'authUser':
                if (this.getAuthType() === 'emailAuthorization') {
                    el = this.getOuter().querySelector('form [name="user_input_password"]');

                    if (!el.value.length) {
                        this.showInputError(el);
                        valid = false;
                    } else data = el.value;

                } else if (this.getAuthType() === 'phoneAuthorization') {

                    el = this.getOuter().querySelectorAll('form [name^="user_input"]');
                    data = '';

                    for (var i = 0; i < el.length; i++) {
                        if (!el[i].value.length) {
                            this.showInputError(el[i]);
                            valid = false;
                        }
                        data += el[i].value;
                    }

                }
                break;
            case 'fillRegisterData':
                el = this.getOuter().querySelectorAll('[name^="user_input"]');

                data = {};
                var item = false;

                // Callect all register data
                for (var i = 0; i < el.length; i++) {
                    item = el.item(i);
                    data[item.getAttribute('name')] = {
                        'value': item.value,
                        'valid': !!(item.value.length),
                        'el': item
                    };
                }

                if (!this.validatePhone(data.user_input_phone.value)) {
                    data.user_input_phone.valid = false;
                }

                if (!this._emailPattern.test(data.user_input_email.value)) {
                    data.user_input_email.valid = false;
                }

                if (!this._namePattern.test(data.user_input_name.value)) {
                    data.user_input_name.valid = false;
                }
                if (!this._lastNamePattern.test(data.user_input_lastName.value)) {
                    data.user_input_lastName.valid = false;
                }

                for (var name in data) {
                    if (!data.hasOwnProperty(name)) continue;
                    if (!data[name].valid) {
                        this.showInputError(data[name].el);
                        valid = false;
                    } else {
                        this.hideError(data[name].el);
                    }
                }
                break;
            case 'fillForgotData':
                el = this.getOuter().querySelectorAll('[name^="user_input"]');

                data = {};
                var item = false;

                // Callect all register data
                for (var i = 0; i < el.length; i++) {
                    item = el.item(i);
                    data[item.getAttribute('name')] = {
                        'value': item.value,
                        'valid': !!(item.value.length),
                        'el': item
                    };
                }

                // Validate register data
                if (data.user_input_password.value.length < 6) {
                    data.user_input_password.valid = false;
                }

                for (var name in data) {
                    if (!data.hasOwnProperty(name)) continue;
                    if (!data[name].valid) {
                        this.showInputError(data[name].el);
                        valid = false;
                    } else {
                        this.hideError(data[name].el);
                    }
                }
                break;
            default:
                throw new Error('Unknown current step - ' + this.getCurrentStep() + '.');
                break;
        }

        return {'data': data, 'valid': valid};
    }

    setAuthParam(param) {
        // Prepare phone number
        if (this.getAuthType() === 'phoneAuthorization' && param.length > 10) {
            if (param.match(/[^0-9]+/g) !== null) {
                param = param.replace(/[^0-9]+/g, '');
            }
            param = param.substr(-10);
        }

        this._authParam = param;
        return this;
    }

    getAuthParam() {
        return this._authParam;
    }

    setAuthValue(value) {
        this._authValue = value;
        return this;
    }

    getAuthValue() {
        return this._authValue;
    }

    searchUserOnRegistration(value) {
        let self = this;
        this.setAuthType(this.resolveAuthType(value)).setAuthParam(value);
        let data = {
            'action': 'searchUser',
            'authParam': this.getAuthParam(),
            'authType': this.getAuthType()
        };
        this.httpRequest(this.url, data, this._contentType).then((response) => {
            try {
                response = JSON.parse(response);
                if (response.hasOwnProperty('found') && response.found === true) {
                    // Show user message
                    if ('errors' in response && response.errors.length) {
                        for (var i in response.errors) {
                            if (!response.errors.hasOwnProperty(i)) continue;
                            if (response.errors[i].type === 'number_of_requests_exceeded') self.showRequestError(response.errors);
                        }
                    } else {
                        self.setCurrentUser(response.user).setCurrentMode('auth').setCurrentStep('authUser').initStep();
                    }
                }
            } catch (e) {
                throw new Error(e.message);
            }
        }, self.showRequestError);
    }

    searchUser(value) {
        let self = this;
        this.setAuthType(this.resolveAuthType(value)).setAuthParam(value);
        let data = {
            'action': 'searchUser',
            'authParam': this.getAuthParam(),
            'authType': this.getAuthType()
        };
        this.httpRequest(this.url, data, this._contentType).then((response) => {
            try {
                response = JSON.parse(response);

                if (response.hasOwnProperty('found') && response.found === false) {
                    self.setCurrentMode('register').setCurrentStep('fillRegisterData').initStep();
                } else if (response.hasOwnProperty('found') && response.found === true) {
                    // Show user message
                    if ('errors' in response && response.errors.length) {
                        for (var i in response.errors) {
                            if (!response.errors.hasOwnProperty(i)) continue;

                            if (response.errors[i].type === 'number_of_requests_exceeded') self.showRequestError(response.errors);
                        }
                    } else {
                        self.setCurrentUser(response.user).setCurrentStep('authUser').initStep();
                    }
                }
                self.sendingRequest = false;
            } catch (e) {
                self.sendingRequest = false;
                throw new Error(e.message);
            }
        }, self.showRequestError);
    }


    authUser() {
        this.eventEmitter.emit('showLoader');
        var self = this, data = {
            'action': 'authUser',
            'authParam': this.getAuthParam(),
            'authValue': this.getAuthValue(),
            'authType': this.getAuthType()
        }, request = this.httpRequest(this.url, data, this._contentType), err = false;

        request.then(
            response => {
                try {
                    response = JSON.parse(response);
                    if (response.hasOwnProperty('authorized') && response.authorized === false) {
                        if ('errors' in response) {
                            for (var i in response.errors) {
                                if (response.errors.hasOwnProperty(i)) {
                                    err = response.errors[i];
                                    if (err.type === 'auth_error_password' || err.type === 'auth_error_code') {
                                        self.showRequestError(response.errors);
                                    }
                                }
                            }
                        }
                        self.sendingRequest = false;
                        self.eventEmitter.emit('hideLoader');
                    } else if (response.hasOwnProperty('authorized') && response.authorized === true) {
                        self.setCurrentStep('authorizedUser').initStep();
                    }
                } catch (e) {
                    self.sendingRequest = false;
                    self.eventEmitter.emit('hideLoader');
                    throw new Error(e.message);
                }
            },
            () => {
                self.eventEmitter.emit('hideLoader');
                self.showRequestError();
            }
        );

        return this;
    }

    resendSmsCode() {
        var self = this, data = {
            'action': 'resendSmsCode',
            'authParam': this.getAuthParam(),
            'authType': this.getAuthType()
        };

        return this.httpRequest(this.url, data, this._contentType);
    }

    getCurrentUser() {
        return this._user;
    }

    setCurrentUser(user) {
        this._user = user;
        return this;
    }

    getCurrentUserId() {
        return (this.getCurrentUser().hasOwnProperty('ID')) ? this.getCurrentUser()['ID'] : false;
    }

    /**
     * Send reset password user email
     * @returns {AuthRegister}
     */
    sendResetPasswordData() {
        var self = this, data = {
            'action': 'sendResetPasswordEmail',
            'id': this.getCurrentUserId()
        }, request = this.httpRequest(this.url, data, this._contentType);

        request.then(function (response) {
            try {
                response = JSON.parse(response);
                if (!response.hasOwnProperty('send')) self.showRequestError(self.getMessage('AUTH_SYSTEM_ERROR'));
                else if (response.send === true) {
                    self.setCurrentMode('forgot').setCurrentStep('fillForgotData').initStep();
                } else if (response.hasOwnProperty('errors') && response.errors.length) {
                    self.showRequestError(response.errors);
                }
            } catch (e) {
                self.showRequestError(e.message);
            }
        }, this.showRequestError);

        return this;
    }

    resetPassword(values) {
        var self = this, data = {
            'action': 'resetPassword',
            'password': values.user_input_password.value,
            'checkword': values.user_input_checkword.value,
            'id': this.getCurrentUserId(),
        }, request = this.httpRequest(this.url, data, this._contentType);


        request.then(function (response) {
            try {
                self.sendingRequest = false;
                response = JSON.parse(response);
            } catch (e) {
                self.sendingRequest = false;
                throw new Error(e.message);
            }

            if (response.hasOwnProperty('reset') && response.reset === false) {
                if ('errors' in response) {
                    response.errors.forEach(function (error) {
                        self.showRequestError(error.message);
                    });
                }
            } else if (response.hasOwnProperty('reset') && response.reset === true) {
                self.setCurrentStep('successPasswordReset').initStep();
            }
        }, self.showRequestError);
    }

    registerUser(values) {
        this.eventEmitter.emit('showLoader');
        var self = this, data = {
            'action': 'registerUser',
            'name': values.user_input_name.value,
            'lastName': values.user_input_lastName.value,
            'email': values.user_input_email.value,
            'phone': values.user_input_phone.value
        }, request = this.httpRequest(this.url, data, this._contentType);

        request.then(response => {
                try {
                    response = JSON.parse(response);
                } catch (e) {
                    self.eventEmitter.emit('hideLoader');
                    throw new Error(e.message);
                }

                if (response.hasOwnProperty('registered') && response.registered === false) {
                    if ('errors' in response) {
                        for (var i in response.errors) {
                            if (response.errors.hasOwnProperty(i) && response.errors[i].type === 'user_exist') {
                                self.showRequestError(response.errors);
                                setTimeout(function () {
                                    self.setCurrentMode('auth').setCurrentStep('searchUser').initStep();
                                }, 3000);
                            }
                        }
                    }
                    self.eventEmitter.emit('hideLoader');
                } else if (response.hasOwnProperty('registered') && response.registered === true) {
                    self.setCurrentStep('registeredUser').initStep();
                }
                self.sendingRequest = false;
            },
            () => {
                self.eventEmitter.emit('hideLoader');
                self.sendingRequest = false;
                self.showRequestError();
            });
    }

    setCurrentStep(step) {
        var steps = this.getModeSteps(this.getCurrentMode());
        if (steps.indexOf(step) === -1) throw new Error('Unknown step - ' + step + '.');
        this._step = step;
        return this;
    }

    enterOtherMail() {
        var self = this;
        this.getOuter().querySelector('form').innerHTML = this.getFormTemplate();
        this.updateModeBlock().clearRequestError();

        // Authorized user 3 sec redirect
        if (this.getCurrentStep() === 'authorizedUser' || this.getCurrentStep() === 'registeredUser')
            setTimeout(function () {
                window.location.reload(true);
            }, 3000);
        else this.eventEmitter.emit('hideLoader');

        if (this.getCurrentStep() === 'successPasswordReset') {
            setTimeout(function () {
                self.setCurrentMode('auth').setCurrentStep('authUser').initStep();
            }, 3000);
        }

        this.addDataLayerEvent();
        let enterOtherMail = document.querySelector('#enter-other-mail').value = '';

        return this;
    }

    getCurrentStep() {
        return this._step;
    }

    setCurrentMode(mode) {
        if (this.getModes().indexOf(mode) === -1) throw new Error('Unknown mode - ' + mode + '.');

        this._mode = mode;
        return this;
    }

    getCurrentMode() {
        return this._mode;
    }

    getSteps() {
        return this._steps;
    }

    getModes() {
        return this._modes;
    }

    getModeSteps(mode) {
        if (this.getModes().indexOf(mode) === -1) throw new Error('Unknown mode - ' + mode + '.');

        return this.getSteps()[mode];
    }

    resolveAuthType(value) {
        if (this.validatePhone(value)) return this._phoneType;
        else if (this._emailPattern.test(value)) return this._emailType;

        return null;
    }

    getAuthType() {
        return this._authType;
    }

    setAuthType(type) {
        this._authType = type;
        return this;
    }

    showInputError(input) {
        input.parentElement.classList.add('error');
        return this;
    }

    hideError(input) {
        input.parentElement.classList.remove('error');
        return this;
    }

    httpRequest(url, data, contentType) {
        // Add request sessid, lang
        data.lang = this.lang;
        data.sessid = this.sessid;

        data = JSON.stringify(data);

        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Content-type', contentType);
            xhr.onload = function () {
                if (this.status === 200) {
                    resolve(this.response);
                } else {
                    var error = new Error(this.statusText);
                    error.code = this.status;
                    reject(error);
                }
            };
            xhr.onerror = function () {
                reject(new Error("Network Error"));
            };
            xhr.send(data);
        });
    }

    setLangMessages(messages) {
        this._messages = messages;
        return this;
    }

    getMessage(name) {
        return (this._messages.hasOwnProperty(name)) ? this._messages[name] : '';
    }

    /**
     * Make auth modal template. {formContent} for replace with form data
     * @returns {string}
     */
    getModalTemplate() {
        // Make root element
        let el = document.createElement('div');
        el.className = 'modal fade';
        el.id = 'modal-main';
        el.setAttribute('role', 'dialog');
        el.tabIndex = '-1';

        el.innerHTML = `<div class="modal-dialog">
											<div class="modal-content modal-content--login">
												<a href="#" class="close-modal" data-dismiss="modal"></a>
												<div class="modal-body modal-body--login">  
													<div class="modal-form-wrap">
														<div class="modal-form-holder">
															<div class="modal-form-enter">					
																<div class="modal-form">
																	<div>
																		<div class="favorites-login-info" style="display: none;"></div>
																		<span class="modal-title">${this.getMessage('AUTH_LOGIN_BUTTON')}</span>
																		<form class="form_validate" id="login_form"  method="POST" action=""></form>
																		<div class="request_error_block"></div>
																	</div>
																</div> 					           
															</div>
														</div>			
													</div>
													<div class="btn-holder">
														<span class="registration-link" data-action="switchMode" data-mode="register">${(this.getCurrentMode() === 'auth') ? this.getMessage('AUTH_LOGIN_REGISTER') : this.getMessage('AUTH_AUTHORIZATION')}</span>
													</div>
												</div>
											</div>
										</div>`;

        return el;
    }

    searchUserTemplate() {
        let authParam = (this.getAuthParam()) ? this.getAuthParam() : '',
            inputClass = (authParam.length) ? 'has-value' : '';
        return `<div class="row">
							<div class="input-holder field auth-field">
							    <span class="country-flag">
                                    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="20" y="14" width="20" height="7" transform="rotate(-180 20 14)" fill="#F3D217"/>
                                        <rect x="20" y="7" width="20" height="7" transform="rotate(-180 20 7)" fill="#0080CC"/>
                                    </svg>
                                </span>									              
								<input id="enter-other-mail" inputmode="tel" name="user_input_email_phone" type="text" autofocus class="input form-control ${inputClass}" value="${authParam}">
								<span class="label-input">${this.getMessage('AUTH_LOGIN_DESCRIPTION')}</span>							
							</div>
						</div>							
						<div class="row">
							<span class="text-info">${this.getMessage('AUTH_USER_PHONE_DESCRIPTION')}</span>
						</div>
						<div class="row submit-holder">
							<input type="submit" class="btn btn-submit" value="${this.getMessage('AUTH_NEXT_BUTTON')}">
						</div>`;
    }

    fillForgotDataTemplate() {
        let hasValue = (this.getAuthParam().length > 0);
        return `<div class="row">
							<div class="input-holder field">									              
								<input name="user_input_checkword" type="text" class="input form-control" autocomplete="off">
								<span class="label-input">${this.getMessage('AUTH_RESTORE_CONTROL_CODE')}</span>
								<span class="error-text">${this.getMessage('AUTH_USER_DATA_ERROR')}</span>									
							</div>
						</div>
						<div class="row">
							<div class="input-holder field password_holder">									              
								<input name="user_input_password" type="password" class="input form-control password_field" autocomplete="off">
								<span class="label-input">${this.getMessage('AUTH_PASSWORD')}</span>
								<span class="error-text">${this.getMessage('AUTH_USER_DATA_ERROR')}</span>									
							</div>
						</div>					
						<div class="row submit-holder">
							<input type="submit" class="btn btn-submit" value="${this.getMessage('AUTH_RESTORE')}">
						</div>
						<div class="forgot-pass_thx">
							<div class="row">
								<span class="thx-text">${this.getMessage('AUTH_RESTORE_SENDED_ON')} <span class="email">${this.getAuthParam()}</span></span>
							</div>
							<div class="row">
								<span class="thx-text">${this.getMessage('AUTH_RESTORE_EMAIL_HINT')}</span>
							</div>
						</div>`;
    }

    fillRegisterDataTemplate() {
        let hasValue = (this.getAuthParam().length > 0);
        return `<div class="row">
							<div class="input-holder field phone">									              
								<input name="user_input_phone" type="tel" placeholder="+380(__)___-__-__" class="input form-control" autocomplete="off">
								<span class="label-input phone">${this.getMessage('AUTH_PHONE')}</span>
								<span class="error-text">${this.getMessage('REGISTER_USER_PHONE_ERROR')}</span>
							</div>
						</div>
						<div class="row">
							<div class="input-holder field">									              
								<input name="user_input_name" type="text" class="input form-control" maxlength="40">
								<span class="label-input">${this.getMessage('AUTH_NAME')}</span>
								<span class="error-text">${this.getMessage('AUTH_USER_DATA_ERROR')}</span>									
							</div>
						</div>
						<div class="row">
							<div class="input-holder field">									              
								<input name="user_input_lastName" type="text" class="input form-control">
								<span class="label-input">${this.getMessage('AUTH_LAST_NAME')}</span>
								<span class="error-text">${this.getMessage('AUTH_USER_DATA_ERROR')}</span>									
							</div>
						</div>
						<div class="row">
							<div class="input-holder field email">									              
								<input name="user_input_email" id="email-input-mask" placeholder="_@_._" type="text" class="input form-control"  autocomplete="off">
								<span class="label-input email">${this.getMessage('AUTH_EMAIL')}</span>
								<span class="error-text">${this.getMessage('AUTH_USER_DATA_ERROR')}</span>									
							</div>
						</div>
						<div class="row submit-holder">
							<input type="submit" class="btn btn-submit" value="${this.getMessage('AUTH_REGISTER')}">
						</div>`;
    }

    authUserTemplate() {
        if (this.getAuthType() === 'phoneAuthorization') {
            return this.authUserPhoneTemplate();
        } else if (this.getAuthType() === 'emailAuthorization') {
            return this.authUserEmailTemplate();
        }
    }

    authorizedUserTemplate() {
        return `<div class="row">${this.getMessage('AUTH_SUCCESFULL')}</div>`;
    }

    successPasswordResetTemplate() {
        return `<div class="row">${this.getMessage('AUTH_RESTORE_SUCCESSFULL')}</div>`;
    }

    authUserEmailTemplate() {
        return `<div class="row">
							<div class="input-holder field password_holder">									              
								<input name="user_input_password" type="password" class="input form-control password_field">
								<span class="label-input">${this.getMessage('AUTH_PASSWORD')}</span>
								<span class="error-text">${this.getMessage('AUTH_USER_DATA_ERROR')}</span>
								<span class="pass_icon"><i class="ic-pass"></i></span>										 
							</div>
						</div>
						<div class="row">
							<a href="#" class="auth_password_forgot">${this.getMessage('AUTH_RESTORE_PASSWORD')}</a>
						</div>
						<div class="row">
							<a href="#" class="change_param">${this.getMessage('AUTH_CHANGE_EMAIL')}</a>
						</div>
						<div class="row submit-holder">
							<input type="submit" class="btn btn-submit" value="${this.getMessage('AUTH_AUTHORIZATION')}">
						</div>`;
    }

    authUserPhoneTemplate() {
        return `<div class="row">
							<span>${this.getMessage('AUTH_INSERT_SMS_CODE')}</span>
						</div>
						<div class="row">
							<div class="input-holder field field-sms">
							    <input name="user_input_1" maxlength="4" size="4" type="tel" pattern="[0-9]{4}" autocomplete="one-time-code" autofocus="autofocus" class="input input-num-sms auth_secret_code">
							    <hr class="border-line">
								<span class="error-text">${this.getMessage('AUTH_SMS_CODE_WRONG')}</span>								 
							</div>
						</div>
						<div class="row">
							<div class="text-holder" id="text-holder">${this.getMessage('AUTH_TIMER_TITLE')} 15 c.</div>
						</div>
						<div class="row">
								<a href="#" class="change_param">${this.getMessage('AUTH_CHANGE_PHONE')}</a>
						</div>
						<div class="row submit-holder">
							<input type="submit" class="btn btn-submit" value="${this.getMessage('AUTH_AUTHORIZATION')}">
						</div>`;
    }

    registeredUserTemplate() {
        return `<div class="row">${this.getMessage('AUTH_SUCCESFULL_REGISTERED')}</div>`;
    }

    getFormTemplate() {
        return this[this.getCurrentStep() + 'Template']();
    }

    getModalBackDrop() {
        let el = document.createElement('div');
        el.className = 'modal-backdrop fade';
        return el;
    }

    clearRequestError() {
        let errorBlock = this.getOuter().querySelector('.request_error_block');
        if (errorBlock instanceof HTMLElement) {
            errorBlock.innerHTML = '';
        }
    }

    showRequestError(messages) {
        this.sendingRequest = false;
        let errorBlock = this.getOuter().querySelector('.request_error_block');
        if (errorBlock instanceof HTMLElement) {
            if (messages instanceof Array) {
                let html = '';
                messages.forEach((item, index) => {
                    html += `<span class="error-text">${(typeof (item) === 'object') ? item.message : item}</span>`;
                });
                errorBlock.innerHTML = html;
            } else {
                errorBlock.innerHTML = `<span class="error-text">${messages}</span>`;
            }
        }

        if (this._phoneConfirmForm instanceof HTMLElement) {
            let errorBlock = this._phoneConfirmForm.querySelector('.error-text');
            if (errorBlock instanceof HTMLElement) {
                if (messages instanceof Array) {
                    let html = '';
                    messages.forEach((item, index) => {
                        html += ' ' + (typeof (item) === 'object') ? item.message : item;
                    });
                    errorBlock.innerHTML = html.trim();
                } else {
                    errorBlock.innerHTML = messages;
                }

                errorBlock.style.display = 'block';
            }
        }

        return this;
    }


    showSpecialMessage(message) {
        let messageNode = this.getOuter().querySelector('.favorites-login-info');
        if (!(messageNode instanceof HTMLElement)) return this;

        messageNode.innerHTML = '<span>' + message + '</span>';
        messageNode.style.display = 'block';
        return this;
    }


    clearSpecialMessage() {
        let messageNode = this.getOuter().querySelector('.favorites-login-info');
        if (!(messageNode instanceof HTMLElement)) return;
        messageNode.innerHTML = '';
    }

    loadPersonalMenu(url) {
        return this.httpRequest(this.menuPath, {'action': 'personalMenu', 'url': url}, 'text/html');
    }

    getPhoneConfirmOutput() {
        // Make root element
        this._phoneConfirmForm = document.createElement('form');
        this._phoneConfirmForm.className = 'order-phone-confirmation';
        this._phoneConfirmForm.id = 'soa-phone-confirmation';
        this._phoneConfirmForm.setAttribute('method', 'POST');

        this._phoneConfirmForm.innerHTML = `
                                            <div class="form_validate" id="checkout_login_form">
                                                <span>${this.getMessage('AUTH_INSERT_SMS_CODE')}</span>
                                                <div class="validate_box">
                                                    <div class="field_validate_number">
                                                        <div class="input-holder field field-sms">
                                                            <input name="user_input_1" maxlength="4" size="4" type="tel" pattern="[0-9]{4}" autocomplete="one-time-code" autofocus="autofocus" required="" class="input input-num-sms auth_secret_code">
                                                            <hr class="border-line">
                                                            <span class="error-text"></span>
                                                        </div>
                                                    </div>
                                                    <div class="field_validate_number">
                                                        <div class="text-holder">
                                                            <a href="#" class="resend_code">${this.getMessage('AUTH_SMS_CODE_RESEND')}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="field_validate_number">
                                                    <div class="field_validate_number">
                                                        <a href="#" class="change_param">${this.getMessage('AUTH_CHANGE_PHONE')}</a>
                                                    </div>
                                                </div>
                                            </div>`;

        return this._phoneConfirmForm;
    }

}

export {AuthRegister}